import {
	UPDATE_AUTH
} from '../actions/actionTypes';

const initialState = {
	isAuthenticated: false,
};

export const authReducer = (state = initialState, action) => {
	switch(action.type) {
		case UPDATE_AUTH:
			return {
				...state,
				isAuthenticated:action.bool
			};
		default:
			return state;
	}
};