import React, { Component } from 'react';
import Routes from './Router';

import './App.css';

class App extends Component {
	render() {
		return <Routes />
	};
};

export default App;
