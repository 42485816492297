import {
	SET_DASHBOARD_SCREEN
} from './actionTypes';

export const setDashboard = (k, v) => {
	return {
		type: SET_DASHBOARD_SCREEN,
		key: k,
		value: v,
	};
};