import {
	SET_AUTH_USER
} from './actionTypes';

export const setAuthUser = (k, v) => {
	return {
		type: SET_AUTH_USER,
		key: k,
		value: v,
	};
};