export {
    authReducer
} from './auth';

export {
    authUserReducer
} from './authUser';

export {
    dashboardReducer
} from './screen';