import React, { Component } from 'react';
import { 
	withRouter,
	Switch,
	Route,
	Redirect,
	BrowserRouter as Router,
	Link
} from 'react-router-dom';

import { connect } from 'react-redux';

import Dashboard from './assets/screens/app/Dashboard';

class Routes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			screenOptions: {
				element: {
					sidebar: {
						css: {
							position: 'fixed',
							top:      '407px'
						}
					}
				},
				buttons: {
					menu: null
				}
			}
		}
	}

	componentDidMount () {
		var stopElement             = document.getElementById("starting_with_benin_bronzes");
		var stopElementDistanceTop  = stopElement.getBoundingClientRect().top;


		var sidebarElement             = document.getElementById("sidebar-wrapper");
		var sidebarElementDistanceTop  = sidebarElement.getBoundingClientRect().top;

		window.addEventListener('scroll', (e) => this.handleSidebar(e, stopElement, stopElementDistanceTop, sidebarElement, sidebarElementDistanceTop));
	}

	handleSidebar (event, stopElement, stopElementDistanceTop, sidebarElement, sidebarElementDistanceTop) {
		var self = this;

		var stopElementScroll            = document.getElementById("starting_with_benin_bronzes");
		var stopElementScrollDistanceTop = stopElement.getBoundingClientRect().top;

		if(sidebarElement.style.position !== 'absolute') {
			if(sidebarElementDistanceTop >= stopElementScrollDistanceTop)
				return self.setState({
					screenOptions: {
						...self.state.screenOptions,
						element: {
							...self.state.screenOptions.element,
							sidebar: {
								...self.state.screenOptions.element.sidebar,
								css: {
									...self.state.screenOptions.element.sidebar.css,
									position: 'absolute',
									top:      `${stopElementDistanceTop}px`
								}
							}
						}
					}
				})

			return;
		}

		if(sidebarElementDistanceTop < stopElementScrollDistanceTop)
			return self.setState({
				screenOptions: {
					...self.state.screenOptions,
					element: {
						...self.state.screenOptions.element,
						sidebar: {
							...self.state.screenOptions.element.sidebar,
							css: {
								...self.state.screenOptions.element.sidebar.css,
								position: 'fixed',
								top:      `${sidebarElementDistanceTop}px`
							}
						}
					}
				}
			})
	}

	render() {
		return(
			<Router>
				<div style={{ width: '100vw', display: !this.state.screenOptions?.buttons?.menu ? 'block' : 'none' }}>
					<div className="noise" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/misc/noise.png'})` }} />
					<div className="container-fluid radial-gradient padding-media-768">
						<div className="container-fluid" style={{ paddingTop: 125, height: '140vh' }}>
							<div className="row">
								<div className="col-12 col-sm-8 pt-5">
									<div>
										<h1 style={{padding:0, margin: '-40px 0 0' }} className="text-uppercase bronze-text bronze-text-h1 bronze-text-white">Supporting</h1>
										<div style={{ 
											marginLeft:     -100,
											display:        'flex',
											flexDirection:  'row',
											alignItems:     'center'
										}}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<hr style={{ width: 115, height: 2.6, color: 'white' }} />
												<div style={{ paddingLeft: 8, paddingRight: 8 }}>
													<img width={40} src={process.env.PUBLIC_URL + '/assets/misc/globe.svg'} alt="globe" />
												</div>
												<hr style={{ width: 16, height: 2.6, color: 'white' }} />
											</div>
											{/*<p style={{ fontStyle: 'italic', paddingLeft: 10 }} className="h3 bronze-text bronze-text-h3 bronze-text-white">global</p>*/}

											<div style={{ paddingLeft: 10 }}>
												<img height={30} src={process.env.PUBLIC_URL + '/assets/misc/global.svg'} alt="global text" />
											</div>
											<p className="h1 bronze-text bronze-text-h1 bronze-text-white text-uppercase" style={{ paddingLeft: 10, margin: '-20px 0 -20px' }}>Repatriation</p>
										</div>
										<div style={{ 
											display:        'flex',
											flexDirection:  'row',
											alignItems:     'center'
										}}>
											{/*<p style={{ fontStyle: 'italic' }} className="h3 bronze-text bronze-text-h3 bronze-text-white">of</p>*/}

											<div style={{}}>
												<img height={30} src={process.env.PUBLIC_URL + '/assets/misc/of.svg'} alt="of text" />
											</div>
											<p className="h1 bronze-text bronze-text-h1 bronze-text-white text-uppercase" style={{ paddingLeft: 10}}>Looted Art.</p>
										</div>
									</div>
									<hr style={{ width: '75%', height: 2.6, color: 'white' }} />
								</div>
							</div>
							<div className="row no-gutters">
								<div className="col-12 col-md-6">
									<p className="fs-5 bronze-text bronze-text-white text-uppercase">Bronze DAO is a multifaceted assembly solving technological and cultural dilemmas through on-chain product incubation, investment, philanthropy, and social curation.</p>
								</div>
								<div className="col-12">
									<div style={{ position: 'absolute', display: 'inline-block'}}>
										<a href="https://docs.google.com/forms/d/e/1FAIpQLSf3mRQckrGvkxdJ1LbvHNiXZ7kuBYcABnrOJhEVYmlQIS3B3Q/viewform?usp=sf_link" className="btn rounded-0" target="_blank" style={{ 
											
											width: 101,
											height: 39, 
											backgroundColor: 'transparent',
											borderColor:'#FFF2DE'
											}}>
											<span className="bronze-button-text bronze-text-white">Join Us</span>
										</a>
										<a href="https://docs.google.com/forms/d/e/1FAIpQLSd0Nqfz91FfIlZAU06tYgDahkS7g0yaCggzuttir6Xe-A9aQQ/viewform?usp=sf_link" className="btn rounded-0" target="_blank" style={{ 
											width: 126,
											height: 39,
											backgroundColor: 'transparent',
											borderColor:'#FFF2DE',
											marginLeft: 8
											}}>
											<span className="bronze-button-text bronze-text-white">Contribute</span>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid" style={{ height: '50vh', display: 'block', alignItems: 'flex-start', paddingBottom: 40 }}>
							<div id="starting_with_benin_bronzes" className="row no-gutters" style={{}}>
								<div className="col-12 col-md-8">
									<p className="fs-5 bronze-text bronze-text-white">Phase 1</p>
									<p className="h1 bronze-text bronze-text-white text-uppercase" style={{ marginTop: -24, whiteSpace: 'nowrap' }}>STARTING WITH</p>
									<p className="h1 bronze-text bronze-text-white text-uppercase" style={{ marginTop: -8, whiteSpace: 'nowrap' }}>BENIN BRONZES.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid radial-gradient">
						<div className="row no-gutters" style={{ position: 'relative' }}>
							<video id="wakanda-video-snippet" className="video-hero" playsInline autoPlay muted>
								<source src="https://storage.googleapis.com/bdao2/Black%20Panther%204.mp4" type="video/mp4" />
								Your browser does not support the video tag.
							</video>
							<div style={{ backgroundColor: 'rgba(0,0,0,.65)', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center' }}>
								<div className="noise" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/misc/noise.png'})` }} />
								<div className="container-fluid padding-media-768">
									<div className="row no-gutters">
										<div className="col-12 col-md-6">
											<p className="fs-5 bronze-text bronze-text-white">What are</p>
											<p className="h1 bronze-text bronze-text-white">BENIN BRONZES</p>
										</div>
									</div>
									<div className="row no-gutters pt-4">
										<div className="col-12 col-md-6">
											<p className="fs-5 text-uppercase bronze-text bronze-text-white">Known as some of Africa’s greatest treasures, the Benin Bronzes were made between the 15th and 19th Centuries in the Kingdom of Benin by the Edo people in modern-day Nigeria.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid media-padding-bottom-768 padding-media-768" style={{
							display: 'flex',
							alignItems: 'center',
							height: '100vh'
							}}>
							<div className="row no-gutters">
								<div className="col-sm-12 col-md-6">
									<p className="fs-5 bronze-text bronze-text-white bronze-no-hyphen text-uppercase">A collection of more than 5,000 Benin Bronzes were looted in 1897 by European Armies who sought to consolidate control of Africa. They still remain in museums and in the hands of private owners. ­Looted Bronze artifacts are estimated to be worth over 3 billion USD.</p>
								</div>
								<div className="col-sm-12 col-md-6 media-padding-top" style={{ display: 'flex', justifyContent: 'center' }}>
									<div className="bronze-warriors-vector-wrapper">
										<img className="bronze-warriors-vector" src={process.env.PUBLIC_URL + '/assets/misc/bronze-vector-rectangle.svg'} alt="Bronze warriors vector" />
										<img className="bronze-warriors" src={process.env.PUBLIC_URL + '/assets/misc/bronze-warriors.png'} alt="Bronze warriors" />
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid padding-media-768" style={{ height: '100vh' }}>
							<div className="row no-gutters">
								<div className="col-12" style={{ paddingBottom: 100 }}>
									<div id="what_and_why_art_repatriation" className="row no-gutters">
										<div className="col-12 col-md-6">
											<p className="fs-5 bronze-text bronze-text-white">What & Why</p>
											<p className="h1 bronze-text bronze-text-white" style={{ marginTop: -24 }}>Art Repatriation</p>
										</div>
									</div>
									<div className="row no-gutters pt-4">
										<div className="col-12 col-md-6">
											<p className="fs-5 bronze-text bronze-text-white text-uppercase">Repatriation is the ethical obligation of all museums and art collections to return objects acquired through questionable, if not illegal, means to their rightful owners, country of origin, or former owners (or their heirs).</p>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="row no-gutters">
										<div className="col-12 col-md-6" style={{ display:'flex', alignItems: 'center', justifyContent: 'center' }}>
											<div className="bronze-head-sphere-vector-wrapper">
												<img className="bronze-head-sphere-vector" src={process.env.PUBLIC_URL + '/assets/misc/bronze-vector-sphere.svg'} alt="Bronze head sphere vector" />
												<div className="bronze-warrior-head-1-circle-wrapper">
													<img className="bronze-warrior-head-1-circle" src={process.env.PUBLIC_URL + '/assets/misc/bronze-vector-warrior-circle-1.svg'} alt="Bronze warrior head 1 circle" />
													<img className="bronze-warrior-head-1" src={process.env.PUBLIC_URL + '/assets/misc/bronze-warrior-head-1.png'} alt="Bronze warrior head 1" />
												</div>

												<div className="bronze-warrior-head-2-circle-wrapper">
													<img className="bronze-warrior-head-2-circle" src={process.env.PUBLIC_URL + '/assets/misc/bronze-vector-warrior-circle-1.svg'} alt="Bronze warrior head 2 circle" />
													<img className="bronze-warrior-head-2" src={process.env.PUBLIC_URL + '/assets/misc/bronze-warrior-head-2.png'} alt="Bronze warrior head 2" />
												</div>
												
											</div>
										</div>
										<div className="col-12 col-md-5 media-padding-top">
											<p className="fs-5 bronze-text bronze-text-white text-uppercase">Starting in 2022, many institutions will repatriate thousands of Benin Bronzes in state holdings. We have the opportunity to support and even expedite this process.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid media-section-viewport-height-768 padding-media-768" style={{ 
							display: 'flex',
							alignItems:'center'
							}}>
							<div className="co1-12">
								<div id="what_is_a_dao" className="row no-gutters">
									<div className="col-12 col-md-11">
										<p className="fs-5 bronze-text bronze-text-white">What is a</p>
										<p className="h1 bronze-text bronze-text-white" style={{ marginTop: -24 }}>Decentralized Autonomous Organization?</p>
									</div>
								</div>
								<div className="row no-gutters pt-4">
									<div className="col-12 col-md-11">
										<p className="fs-5 bronze-text bronze-text-white text-uppercase">A Decentralized Autonomous Organization (DAO) is a self-organizing group. Members collectively decide on its purpose, goals, and roadmap. Anyone can contribute to a DAO regardless of location. The DAO makes decisions collectively and maintains decentralized governance.</p>
									</div>
								</div>
							</div>
						</div>
					
						{/* start footer */}
						<div className="row no-gutters" style={{}}>
							<div className="container-fluid footer-grid">
								<div className="row no-gutters" style={{ height: '100%' }}>
									<div className="col-12 col-md-4" />
									<div className="col-12 col-md-4" />
									<div className="col-12 col-md-4" />
								</div>
							</div>
						</div>
					</div>
					{/* start footer */}
					<div style={{position: 'absolute', bottom:0, left: 0, right:0}}>
						<div className="row no-gutters">
							<div className="container-fluid footer-grid">
								<div className="row no-gutters" style={{ height: '100%' }}>
									<a href="https://docs.google.com/forms/d/e/1FAIpQLSf3mRQckrGvkxdJ1LbvHNiXZ7kuBYcABnrOJhEVYmlQIS3B3Q/viewform?usp=sf_link" target="_blank" className="col-12 col-md-4 footer-tile" style={{ 
										display: 'flex', 
										justifyContent: 'center', 
										paddingTop: 60,
										borderTop: '1px solid #FFF2DE',
										textDecoration:'none'
										}}>
										<p className="h5 bronze-text bronze-text-white footer-tile-text">Join us</p>
									</a>
									<a href="https://docs.google.com/forms/d/e/1FAIpQLSd0Nqfz91FfIlZAU06tYgDahkS7g0yaCggzuttir6Xe-A9aQQ/viewform?usp=sf_link" target="_blank" className="col-12 col-md-4 footer-tile" style={{ 
										display: 'flex', 
										justifyContent: 'center', 
										paddingTop: 60,
										borderTop: '1px solid #FFF2DE',
										borderLeft: '1px solid #FFF2DE',
										borderRight: '1px solid #FFF2DE',
										textDecoration:'none'
										}}>
										<p className="h5 bronze-text bronze-text-white footer-tile-text">Contribute</p>
									</a>
									<a href="https://twitter.com/bronzedaotweets" target="_blank" className="col-12 col-md-4 footer-tile" style={{
										display: 'flex', 
										justifyContent: 'center', 
										paddingTop: 60,
										borderTop: '1px solid #FFF2DE',
										textDecoration:'none'
									}}>
										<p className="h5 bronze-text bronze-text-white footer-tile-text">Follow</p>
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* end footer */}
					
					<div style={{
						position: 'absolute',
						left:0,
						right:0,
						top:0,
						height: 104,
						display: 'flex', 
						alignItems: 'center', 
						justifyContent: 'center'
					}}>
						<h1 className="bronze-text bronze-text-white" style={{ padding:0, margin:0, fontSize: 40 }}>BRONZE</h1>
					</div>

					{/* start main nav */}
					<div
						style={{ display:'flex', alignItems: 'center', justifyContent: 'center' }}
						className={`nav-bronze-container`}>
						<button 
							className="btn" 
							style={{ 
								position: 'absolute', 
								left: 34, 
								display: 'flex',
								alignItems: 'center',
								justifyContent:'center',
								outline: 'none', 
								boxShadow: 'none',
								backgroundColor: 'transparent',
								//borderColor:'#FFF2DE'
							}}
							onClick={() => {
								this.setState({
									screenOptions: {
										...this.state.screenOptions,
										buttons: {
											...this.state.screenOptions.buttons,
											menu: true
										}
									}
								})
							}}
							>
							<span className="material-icons md-dark bronze-text-white">menu</span>
						</button>

						<a href="https://mailxto.com/bqfc4g" className="btn rounded-0 bronze-button-wrapper-media-768" style={{ 
							position: 'absolute', 
							right: 21,
							backgroundColor: 'transparent',
							borderColor:'#FFF2DE'
							}}>
							<span className="bronze-button-text-media-768 bronze-text-white">Contact Us</span>
						</a>
					</div>
					{/* end main nav */}

					{/* start sidebar */}
					<div id="sidebar-wrapper" className="sidebar-wrapper" style={{ ...this.state.screenOptions.element.sidebar.css }}>
						<img className="sidebar-vector" src={process.env.PUBLIC_URL + '/assets/misc/sidebar-vector.png'} alt="Sidebar vector" />
						<img className="sidebar-warrior" src={process.env.PUBLIC_URL + '/assets/misc/sidebar-warrior.png'} alt="Sidebar warrior" />
					</div>
					{/* end sidebar */}
				</div>
				{/* start menu */}
				<div style={{ display: this.state.screenOptions.buttons.menu ? 'block': 'none' }} className={`sidebar-bronze`}>
					<div className="container-fluid radial-gradient">
						<div className="row no-gutters">
							<div className="noise menu-noise-extra-height" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/misc/noise.png'})`}} />
							<div className="container-fluid" style={{ position: 'relative', paddingTop: 150 }}>
								<div className="row">
									<div className="col-12 menu-link-left-padding" style={{ 
										display: 'block',
										paddingTop: '30px',
										paddingBottom: '30px',
										textDecoration:'none'
										}}>
										<p className="h5 bronze-text bronze-text-white">Menu</p>
									</div>
									<a href="#what_are_benin_bronzes" onClick={() => this.setState({
										screenOptions: {
											...this.state.screenOptions,
											buttons: {
												...this.state.screenOptions.buttons,
												menu: false
											}
										}
									})} className="col-12 menu-link menu-link-left-padding" style={{ 
										display: 'block', 
										paddingTop: '30px',
										paddingBottom: '30px',
										borderTop: '1px solid #FFF2DE',
										textDecoration:'none'
										}}>
										<p className="h5 bronze-text bronze-text-white menu-link-text">What are Benin Bronzes?</p>
									</a>
									<a href="#what_and_why_art_repatriation" onClick={() => this.setState({
										screenOptions: {
											...this.state.screenOptions,
											buttons: {
												...this.state.screenOptions.buttons,
												menu: false
											}
										}
									})} className="col-12 menu-link menu-link-left-padding" style={{ 
										display: 'block', 
										paddingTop: '30px',
										paddingBottom: '30px',
										borderTop: '1px solid #FFF2DE',
										textDecoration:'none'
										}}>
										<p className="h5 bronze-text bronze-text-white menu-link-text">What and why Art Repatriation?</p>
									</a>
									<a href="#what_is_a_dao" onClick={() => this.setState({
										screenOptions: {
											...this.state.screenOptions,
											buttons: {
												...this.state.screenOptions.buttons,
												menu: false
											}
										}
									})} className="col-12 menu-link menu-link-left-padding" style={{ 
										display: 'block', 
										paddingTop: '30px',
										paddingBottom: '30px',
										borderTop: '1px solid #FFF2DE',
										textDecoration:'none'
										}}>
										<p className="h5 bronze-text bronze-text-white menu-link-text">What is a DAO?</p>
									</a>
								</div>
								<div className="row no-gutters">
									{/* start footer */}
										<div className="container-fluid footer-grid">
											<div className="row no-gutters" style={{ height: '100%' }}>
												<a href="https://docs.google.com/forms/d/e/1FAIpQLSf3mRQckrGvkxdJ1LbvHNiXZ7kuBYcABnrOJhEVYmlQIS3B3Q/viewform?usp=sf_link" target="_blank" className="col-12 col-md-4 footer-tile" style={{ 
													display: 'flex', 
													justifyContent: 'center', 
													paddingTop: 60,
													borderTop: '1px solid #FFF2DE',
													textDecoration:'none'
													}}>
													<p className="h5 bronze-text bronze-text-white footer-tile-text">Join us</p>
												</a>
												<a href="https://docs.google.com/forms/d/e/1FAIpQLSd0Nqfz91FfIlZAU06tYgDahkS7g0yaCggzuttir6Xe-A9aQQ/viewform?usp=sf_link" target="_blank" className="col-12 col-md-4 footer-tile" style={{ 
													display: 'flex', 
													justifyContent: 'center', 
													paddingTop: 60,
													borderTop: '1px solid #FFF2DE',
													borderLeft: '1px solid #FFF2DE',
													borderRight: '1px solid #FFF2DE',
													textDecoration:'none'
													}}>
													<p className="h5 bronze-text bronze-text-white footer-tile-text">Contribute</p>
												</a>
												<a href="https://twitter.com/bronzedaotweets" target="_blank" className="col-12 col-md-4 footer-tile" style={{
													display: 'flex', 
													justifyContent: 'center', 
													paddingTop: 60,
													borderTop: '1px solid #FFF2DE',
													textDecoration:'none'
												}}>
													<p className="h5 bronze-text bronze-text-white footer-tile-text">Follow</p>
												</a>
											</div>
										</div>
									{/* end footer */}
								</div>
							</div>
						</div>
					</div>
					<div
						style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						className={`nav-bronze-container`}>
						<button 
							className="btn" 
							style={{ 
								position: 'absolute', 
								left: 34, 
								display: 'flex',
								alignItems: 'center',
								justifyContent:'center',
								outline: 'none', 
								boxShadow: 'none',
								backgroundColor: 'transparent',
								//borderColor:'#FFF2DE'
							}}
							onClick={() => {
								this.setState({
									screenOptions: {
										...this.state.screenOptions,
										buttons: {
											...this.state.screenOptions.buttons,
											menu: false
										}
									}
								})
							}}
							>
							<span className="material-icons md-dark bronze-text-white">close</span>
						</button>
					</div>
					<div style={{
						position: 'absolute',
						left:0,
						right:0,
						top:0,
						height: 104,
						display: 'flex', 
						alignItems: 'center', 
						justifyContent: 'center'
					}}>
						<h1 className="bronze-text bronze-text-white" style={{ padding:0, margin:0, fontSize: 40 }}>BRONZE</h1>
					</div>
				</div>
				{/* end menu */}
			</Router>
		);
	};
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Routes);