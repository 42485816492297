import {
	createStore, 
	combineReducers, 
	applyMiddleware
} from 'redux';

import reduxThunk from 'redux-thunk';
import { 
	authReducer, 
	authUserReducer,
	dashboardReducer
} from './reducers/index';

const rootReducer = combineReducers({
	authObj:   authReducer,
	authUser:  authUserReducer,
	dashboard: dashboardReducer
});

const configureStore = () => {
	return createStore(rootReducer, {}, applyMiddleware(reduxThunk));
};
export default configureStore;