import {
	SET_AUTH_USER
} from '../actions/actionTypes';

const initialState = {
	resource: null
};

export const authUserReducer = (state = initialState, action) => {
	switch(action.type) {
		case SET_AUTH_USER:
			return Object.assign({}, state, {[action.key]: action.value});
		default:
			return state;
	}
};