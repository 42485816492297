import {
	UPDATE_AUTH
} from './actionTypes';

export const updateAuth = bool => {
	return {
		type:UPDATE_AUTH,
		bool:bool
	};
};






