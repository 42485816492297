import {
    SET_DASHBOARD_SCREEN
} from '../actions/actionTypes';

export const dashboardReducer = (state = {
    screenOptions: {
        timePeriod: 'week',
        reIndex: {
            sessions: null
        },
        filters: {
            minDate: null,
            maxDate: null,
        },
    },
    
	sessions:  {
        indexByAuthUser: {
            collection: [],
            indexOptions: {
                timePeriod: 'week',
                filters: {
                    minDate: null,
                    maxDate: null,
                }
            },
        },
        review: {
            resource_options: {},
            resource: {},
            filter_options: {
                minDate: null,
                maxDate: null,
            },
            filters: {
                minDate: null,
                maxDate: null,
            }
        },
        show: {
            resource_options: {},
            resource: {},
            filter_options: {
                minDate: null,
                maxDate: null,
            },
            filters: {
                minDate: null,
                maxDate: null,
            }
        }
    },
}, action) => {
	switch (action.type) {
		case SET_DASHBOARD_SCREEN:
			return Object.assign({}, state, {[action.key]: action.value});
		default:
			return state;
	}
};