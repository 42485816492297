import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
	setDashboard
} from '../../../store/actions/index';

class Dashboard extends Component {
    render() {
        return (
        <div className="container-fluid">
            <div className="container-fluid">
                <div>
                    <header className="row text-center">
                        <h1 style={{}}>Dashboard</h1>
                    </header>
                </div>
            </div>
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 py-2">
                        <div className="card" style={{ backgroundColor: 'white' }}>
                            <p className="h3">Card 1</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 py-2">
                        <div className="card" style={{ backgroundColor: 'white' }}>
                            <p className="h3">Card 2</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 py-2">
                        <div className="card" style={{ backgroundColor: 'white' }}>
                            <p className="h3">Card 3</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    };
};

const mapStateToProps = state => {
	return {
        dashboard: state.dashboard
    };
};

const mapDispatchToProps = dispatch => {
	return {
        setDashboard: (k, v) => dispatch(setDashboard(k, v)),
    };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);